import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import SEO from "../components/seo"
import LayoutExtra from "../components/layoutExtra"
import Submit from "../components/submit"

const RecruitSubmit = ({location}) => {

	// 値の受け渡しなければ採用エントリー画面に遷移
	if( location.state === null ) navigate( "/recruit/" )

  const data = useStaticQuery(graphql`
    query {
			applicationIcon: file(relativePath: { eq: "icons/application.svg" }) {
        publicURL
      },
    }
	`)

	return (
		<LayoutExtra>
			<SEO title="採用エントリー内容確認" />
			<div className="page-head">
				<div className="h2-subtitle">
					<img src={data.applicationIcon.publicURL} alt="採⽤エントリーフォーム"/>
					<h2>採⽤エントリーフォーム　内容確認</h2>
					<p>Application Form</p>
				</div>
				<p>
					以下の内容で間違いがなければ、「送信する」ボタンを押してください<br className="d-none d-lg-block" />
					修正される場合は「入力画面に戻る」ボタンを押してください。
				</p>
			</div>
			<Submit 
				state={location.state} 
			/>
		</LayoutExtra>
	)
}

export default RecruitSubmit